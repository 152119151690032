import {React, useEffect, useState} from "react";
import { createBrowserRouter, RouterProvider, Routes, Route } from 'react-router-dom';
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsalAuthentication, useMsal } from "@azure/msal-react";
import { Provider } from "react-redux";
import store from "./store";
import Layout from "./components/layout/Layout";
import { Requests } from "./components/Requests";
import { ApolloProvider } from '@apollo/client/react';
import { GraphQlClient } from './apolloClient';
import { MakeRequest } from './components/MakeRequest';
import { Tasks } from './components/Tasks';
import { ViewTask } from './components/ViewTask';
import { CustomNavigationClient } from './NavigationClient';
import { useSelector } from "react-redux";
import { ManageUsers } from './components/ManageUsers';
import { ViewRequest } from './components/ViewRequest';
import { EditReviewTypes } from './components/EditReviewTypes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { PDFEditor } from "./components/PDFEditor";
import { DownloadfilePage } from './components/DownloadFilePage';

const App = ({logAuth,pca}) => {

  ////https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
  ////see above link: custom navigation is not needed
  // const navigate = useNavigate();
  // let navigationClient = new CustomNavigationClient(navigate);
  // pca.setNavigationClient(navigationClient);

  // useEffect(() => {
  //   console.log("app init");
  // }, []);

  const router = createBrowserRouter([
    { path: "*", element: <AppComponent logAuth={logAuth} /> } // <-- pass prop value
  ]);

  return (
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <RouterProvider router={router} />
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
        />
      </MsalProvider>
    </Provider>
  );
}

export default App;


const AppComponent = ({logAuth}) => {
  const isAuthenticated = useIsAuthenticated();
  const { error } = useMsalAuthentication(InteractionType.Redirect);

  const { instance, accounts, inProgress } = useMsal();

  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0) {
      if (logAuth)
        console.log("Getting access token");
        // Retrieve an access token
        instance.acquireTokenSilent({
            account: accounts[0],
            scopes: ["User.Read"]
        }).then(response => {
            if (response.accessToken) {
                if (logAuth)
                  console.log("got access token");
                setAccessToken(response.accessToken);
                setInterval(() => {
                  refreshAccessKey();
                }, 1000 * 60 * 45); //refresh every 45 minutes
            } else {
              if (logAuth)
                console.log("failed to get access token");
            }
        });
    }
}, [inProgress, accounts, instance]);

  const currentUser = useSelector(state => state.auth);

  function refreshAccessKey() {
    //Attempt to grab new access token to maintain session
    instance.acquireTokenSilent({
      account: accounts[0],
      scopes: ["User.Read"]
    }).then(response => {
      if (response.accessToken) {
        setAccessToken(response.accessToken);
      } else {
        if (logAuth)
          console.log("failed to get access token");
      }
    }).catch(
      async (error) => {
            // fallback to interaction when silent call fails
            // should only occur if page is open for >24 hrs
            await instance.acquireTokenRedirect({
                scopes: ["User.Read"]
            })
      }
    );
  }


  useEffect(() => {
    if (logAuth)
      console.log("app component init");
  }, []);

  if(isAuthenticated) {
    return(
      (currentUser.jwtIdToken) ? 
        <ApolloProvider client={GraphQlClient(currentUser.jwtIdToken.idToken)}>
              <Routes>
                <Route element={<Layout/>}>
                  <Route path="/" element={<Tasks />}/>
                  <Route path="/createrequest/:parentRequestId?/:revisionOrAttempt?" element={<MakeRequest />}/>
                  {/* <Route path="/createrequest" element={<MakeRequest />}/> */}
                  <Route path="/requests" element={<Requests />}/>
                  <Route path="/tasks" element={<Tasks />}/>
                  <Route path="/edit/:requestId/:taskId/:itemId/:fileName" element={<PDFEditor />}/>
                  <Route path="/download/:itemId/:withAnnotation/:fileName" element={<DownloadfilePage />}/>
                  <Route path="/viewtask/:taskId" element={<ViewTask />}/>
                  <Route path="/viewrequest/:requestId" element={<ViewRequest />}/>
                  <Route path="/manageusers" element={<ManageUsers />}/>
                  <Route path="/editreviewtypes" element={<EditReviewTypes />} />
                </Route>
              </Routes>
        </ApolloProvider>
      :
        <p>Loading. Please wait</p>
    );
  }
  else if (error) {
    return <span>An error occurred during login</span>
  } 
  else {
    return <span>Please wait. Redirecting to login.</span>
  }
}