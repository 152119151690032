import React, { useState, useEffect } from 'react';
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client';
import {
    Container,
    Button,
    CircularProgress,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    FormControlLabel,
    Checkbox,
    Dialog,
    TextField,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    DialogTitle,
    DialogActions
} from "@mui/material";
import { grey } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CustomCircularProgress from './UiComponents/CustomCircularProgress'
import { useParams } from 'react-router-dom';
import Axios from "axios";
import { getBaseURL } from '../baseURL';
import { useSelector } from "react-redux";
import  FileDownload  from 'js-file-download';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { PeoplePicker } from './PeoplePicker';
import { DividerWithText } from './UiComponents/DividerWithText';

export const ViewRequest = () => {

    const { requestId } = useParams();
    const currentUser = useSelector(state => state.auth);
    const navigate = useNavigate();

    const config = {
        headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken},
        responseType: 'blob'
      };

    const GET_REQUEST = gql`
        query Requests($requestId: Int, $viewingSingle: Boolean) {
            requests(requestId: $requestId, viewingSingle: $viewingSingle) {
                id,
                userId,
                archived,
                documentNumber,
                dateCreated,
                dateRequired,
                description,
                reviewType,
                publicationName,
                approved,
                complete, 
                attemptCount,
                revisionCount,
                numberApproved,
                numberRejected,
                numberApprovedNoResponse,
                requestorUser {
                    email,
                    guid
                },
                tasks {
                    id,
                    approved,
                    comments,
                    assignedTo {
                        firstName,
                        lastName,
                        email
                    }
                },
                attachments {
                    id,
                    fileName,
                    taskId,
                    dateUploaded,
                    uploadedByUser {
                        firstName,
                        lastName
                    },
                    annotatedObjectID
                }
            }
        }
    `;

    const GET_REVIEW_TYPES = gql`
        query ReviewTypes{
            reviewType{
            id,
            name,
            description
            }
        }
	`;

    const UPDATE_TASK = gql`
        mutation UpdateTask($taskId: Int!, $isActive: Boolean) {
            updateTask(taskId: $taskId, isActive: $isActive) {
                assignedTo {
                    email
                }
            }
        }
    `;

    const UPDATE_REQUEST = gql`
        mutation UpdateRequest($requestId: Int!, $archived: Boolean, $publicationName: String!, $documentNumber: String!, $description: String!) {
            updateRequest(id: $requestId, archived: $archived, description: $description, publicationName: $publicationName, documentNumber: $documentNumber) {
                id,
                documentNumber,
                dateCreated,
                dateRequired,
                description,
                reviewType,
                publicationName,
                approved,
                attemptCount,
                revisionCount,
                requestorUser {
                    email
                },
                tasks {
                    id,
                    approved,
                    comments,
                    assignedTo {
                        firstName,
                        lastName,
                        email
                    }
                },
                attachments {
                    id,
                    fileName,
                    taskId,
                    dateUploaded,
                    uploadedByUser {
                        firstName,
                        lastName
                    }
                }
            }
        }
    `;

    const ADD_REVIEWER_TO_REQUEST = gql`
        mutation AddReviewerToRequest($id: Int!, $userGuid: String!) {
            addReviewerToRequest(id: $id, userGuid: $userGuid) {
                id
            }
        }
    `;

    const DELETE_REQUEST = gql`
        mutation DeleteRequest($id: Int!, $latestRequestId: Int!) {
            deleteRequest(id: $id, latestRequestId: $latestRequestId) {
                id
            }
        }
    `;

    const reviewTypeQuery = useQuery(GET_REVIEW_TYPES);
    //const requestQuery = useQuery(GET_REQUEST, {variables: {requestId: parseInt(requestId), viewingSingle: true}});
    const [requestQuery, requestQueryResult] = useLazyQuery(GET_REQUEST);
    //const taskQuery = useLazyQuery(GET_TASK);
    const [updateTask, updateTaskResult] = useMutation(UPDATE_TASK);
    //const [loadingAnnotationInfo, setLoadingAnnotationInfo] = useState(true);
    const [updateRequest, updateRequestResult] = useMutation(UPDATE_REQUEST);

    const [addUserToRequest, addUserToRequestResult] = useMutation(ADD_REVIEWER_TO_REQUEST);
    const [selectedAccordianIndex, setSelectedAccordianIndex] = useState(0);

    const [tasks, setTasks] = useState([]);
    const [requests, setRequests] = useState([]);
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
    const [newReviewer, setNewReviewer] = useState();
    const [requestAddReviewer, setRequestAddReviewer] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [currentUserId, setCurrentUserId] = useState('');

    const [deleteRequestDialogOpen, setDeleteRequestDialogOpen] = useState(false);
    const [requestForDelete, setRequestForDelete] = useState(null);
    const [deleteRequest, deleteRequestResult] = useMutation(DELETE_REQUEST);

    const downloadFile = (requestId, attachmentId, fileName, withAnnotations) => {
        var requestsCopy = [...requests]

        //Set attachment to downloading so button can reflect that
        requestsCopy.find(r => r.id === requestId).attachments.find(a => a.id === attachmentId).loading = true;
        setRequests(requestsCopy);


        Axios.get(`${getBaseURL()}/filedownload/${attachmentId}/${withAnnotations}`, config)
        .then((response) => {
            requestsCopy = [...requests]
            requestsCopy.find(r => r.id === requestId).attachments.find(a => a.id === attachmentId).loading = false;
            FileDownload(response.data, fileName);
            setRequests(requestsCopy);
        })
        .catch((error) => {
            requestsCopy = [...requests]
            requestsCopy.find(r => r.id === requestId).attachments.find(a => a.id === attachmentId).loading = false;
            setRequests(requestsCopy);
        });
    }

    useEffect(() => {
        //onMount
        requestQuery({variables: {requestId: parseInt(requestId), viewingSingle: true}});
    },[requestId,requestQuery]);

    useEffect(() => {
        if (!currentUser) return;
        // console.log('currentUser', currentUser);
        setIsAdmin(currentUser.user.userAttributes.findIndex(x => x.value && x.attribute.attributeName === 'Admin Users') >= 0)
        setCurrentUserId(currentUser.jwtIdToken.uniqueId);
    }, [currentUser])

    useEffect(() => {
        if(requestQueryResult.data && requestQueryResult.data.requests) {
            // console.log('requestQueryResult.data.requests', requestQueryResult.data.requests);
            setRequests(requestQueryResult.data.requests);
        }
    }, [requestQueryResult]);

    const isReviewerFileDownloading = (requestId, fileId) => {
        try{
            if(requests.find(r => r.id === requestId).attachments.find(a => a.id === fileId).loading === true) {
                return true;
            }
            else {
                return false;
            }
        }
        catch {
            return false;
        }
    }

    const onRemoveUserClick = (requestId, taskId, email) => {
        var requestsCopy = [...requests]
        requestsCopy.find(r => r.id === requestId).tasks.find(t => t.id === taskId).removalLoading = true;
        setRequests(requestsCopy);

        updateTask({variables: {taskId: taskId, isActive: false}})
            .then(() => {
                toast.success(`User "${email}" removed`, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    hideProgressBar: false
                });
                requestQuery({variables: {requestId: parseInt(requestId), viewingSingle: true}});
            })
            .catch(() => {
                toast.error(`A problem occurred removing user ${email}`, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    hideProgressBar: false
                });
            });
    }

    const requestArchivedChanged = (request, archived) => {
        updateRequest({variables: {requestId: request.id, archived: archived, publicationName: request.publicationName, documentNumber: request.documentNumber, description: request.description}})
        .then(() => {
            toast.success(`Request Archived Successfully`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            requestQuery({variables: {requestId: parseInt(requestId), viewingSingle: true}});
        })
        .catch(() => {
            toast.error(`A problem occurred archiving request`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        });
    }

    const onReviewDescriptionUpdate = (id, description) => {
        var requestsCopy = [...requests]
        requestsCopy.find(r => r.id === id).description = description;

        setRequests(requestsCopy);
    }

    const onPublicationNameUpdate = (id, publicationName) => {
        var requestsCopy = [...requests]
        requestsCopy.find(r => r.id === id).publicationName = publicationName;

        setRequests(requestsCopy);
    }

    const onDocumentNumberUpdate = (id, documentNumber) => {
        var requestsCopy = [...requests]
        requestsCopy.find(r => r.id === id).documentNumber = documentNumber;

        setRequests(requestsCopy);
    }

    const onRequestSave = (request) => {
        updateRequest({variables: {requestId: request.id, publicationName: request.publicationName, documentNumber: request.documentNumber, description: request.description}})
        .then(() => {
            toast.success(`Request Updated Successfully`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            requestQuery({variables: {requestId: parseInt(requestId), viewingSingle: true}});
        })
        .catch(() => {
            toast.error(`A problem occurred updating request`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        });
    }

    const onAddReviewerToRequest = (request, user) => {
        const currentRequest = requests.find(r => r.id === request);

        let taskEmails = null;

        if (currentRequest?.tasks.length > 0) {
            taskEmails = currentRequest.tasks.map(task => task.assignedTo?.email);
        }

        if (taskEmails?.length > 0) {
            const currentEmailInTasks = taskEmails.find(e => e === newReviewer.secondaryText);
            if (currentEmailInTasks) {
                toast.error('User is already a reviewer', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    hideProgressBar: false
                });
                return;
            }
        }

        addUserToRequest({variables: {id: request, userGuid: user}})
        .then(() => {
            toast.success(`Added User Successfully`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            requestQuery({variables: {requestId: parseInt(requestId), viewingSingle: true}});

            setAddUserDialogOpen(false);
        })
        .catch(() => {
            toast.error(`A problem occurred adding user.`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            setAddUserDialogOpen(false);
        });
    }

    const handleDeleteRequest = (request) => {
        // console.log('handleDeleteRequest: latestRequestId: ', requestId);
        // console.log('handleDeleteRequest', request);
        deleteRequest({variables: {id: request.id, latestRequestId: parseInt(requestId)}})
        .then((result) => {
            // console.log('deleted result: ', result);

            toast.success(`Request Deleted`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            if (result.data.deleteRequest === null || result.data.deleteRequest === undefined) {
                //mutation returns null if there are no more requests to display
                // console.log('navigate to requests');
                navigate(`/requests`, { replace: true });
                return;
            }

            const resultId = parseInt(result.data.deleteRequest.id);
            // console.log(`resultId: ${typeof resultId}`, resultId);
            // console.log(`requestId: ${typeof requestId}`, requestId);
            // console.log('resultId !== requestId: ', resultId !== requestId);

            //returns the id of the the most recent request
            if (parseInt(requestId) !== resultId){
                // console.log('navigate to viewrequest w/ new id');
                resetStateAfterDelete();
                navigate(`/viewrequest/${resultId}`, { replace: true })
            }
            else {
                // console.log('navigate to viewrequest w/ same id');
                resetStateAfterDelete();
                //navigate(`/viewrequest/${resultId}`, { replace: true })
                requestQuery({variables: {requestId: parseInt(requestId), viewingSingle: true}});
            }
        })
        .catch((e) => {
            toast.error(`A problem occurred deleting request.`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
            console.error(e);
        });
    }

    const handleDialogClose = () => {
        setAddUserDialogOpen(false);
    }

    const cancelDeleteDialog = () => {
        setDeleteRequestDialogOpen(false);
        setRequestForDelete(null);
    }

    const resetStateAfterDelete = () => {
        setSelectedAccordianIndex(0);
        setAddUserDialogOpen(false);
        cancelDeleteDialog();
    }

    const isCompletedRequest = (request) => {
        return new Date(request.dateRequired) < new Date() || request.tasks.every(t => t.approved !== null) || request.complete === true;
    }
    const userCanDelete = (request) => {
        return isAdmin || currentUserId === request.userId || currentUserId === request.requestorUser.guid;
    }

    const canDelete = (request) =>  {
        return userCanDelete(request) && !isCompletedRequest(request);
    }

    return(
        <Container key={`${requestId}-${requests.map(r => r.id).join('.')}`} maxWidth={false} disableGutters={true}>
            {((requests.length > 0) && reviewTypeQuery.data) ?
                <>
                    {requests.map((item, index) => {
                        return(
                            <React.Fragment key={`request${index}`}>
                                {index === 1 &&
                                    <DividerWithText><ArrowUpwardIcon /> <Typography variant="subtitle1" style={{display: 'inline-block'}}>Most Recent</Typography></DividerWithText>
                                }
                                <Accordion expanded={index===selectedAccordianIndex} onChange={() => {setSelectedAccordianIndex(index)}}>
                                <AccordionSummary>
                                <>
                                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" style={{marginTop: '10px'}}>
                                        <span style={{flex: 3}}>
                                            <Typography variant="h6" style={{display: 'inline-block'}}>Approved: {item.numberApproved}</Typography>
                                            <Typography variant="h6" style={{display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>Rejected: {item.numberRejected}</Typography>
                                            <Typography variant="h6" style={{display: 'inline-block'}}>Approved-No Response: {item.numberApprovedNoResponse}</Typography>
                                        </span>

                                        <Typography variant="h6">Revision: {item.revisionCount}&nbsp;Attempt: {item.attemptCount + 1}</Typography>

                                        {index === 0 &&
                                            <React.Fragment>
                                                <Button
                                                    color="primary"
                                                    style={{marginRight: '10px'}}
                                                    onClick={() => navigate("/createrequest/" + item.id + "/revision")}
                                                >
                                                    New Revision
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    onClick={() => navigate("/createrequest/" + item.id + "/attempt")}
                                                >
                                                    New Attempt
                                                </Button>
                                            </React.Fragment>
                                        }
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={item.archived}
                                                    onChange={(e) => {requestArchivedChanged(item, e.target.checked)}}
                                                    color="primary"
                                                />
                                            }
                                            label="Archived"
                                            style={{marginLeft: '10px'}}
                                        />
                                        {canDelete(item) &&
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    setRequestForDelete(item);
                                                    setDeleteRequestDialogOpen(true);
                                                }}
                                            >Delete Request</Button>
                                        }
                                    </Grid>
                                </>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{
                                        width: '100%'
                                    }}>
                                    <Paper sx={{px:2, py:2, '& .MuiTextField-root': {m:1}}}>
                                        <Grid container>
                                            <Grid item>
                                                <Typography variant="h6">Review Type:&nbsp;</Typography>
                                            </Grid>
                                            <Grid item style={{paddingTop: '3px'}}>
                                                <Typography variant="h6">{reviewTypeQuery.data.reviewType.find(t => t.id == item.reviewType).name}</Typography>
                                            </Grid>
                                        </Grid>
                                        <TextField
                                            variant="outlined"
                                            color="primary"
                                            label="Publication Name"
                                            fullWidth
                                            value={item.publicationName}
                                            onChange={(e) => {onPublicationNameUpdate(item.id, e.target.value);}}
                                        />
                                        <TextField
                                            variant="outlined"
                                            color="primary"
                                            label="Document Number"
                                            fullWidth
                                            value={item.documentNumber}
                                            onChange={(e) => {onDocumentNumberUpdate(item.id, e.target.value);}}
                                        />
                                        <Grid container>
                                            <Grid item>
                                                <Typography variant="h6">Originator:&nbsp;</Typography>
                                            </Grid>
                                            <Grid item style={{paddingTop: '3px'}}>
                                                <Typography variant="h6">{item.requestorUser.email}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography variant="h6">Description:</Typography>
                                        <TextField
                                            variant="outlined"
                                            color="primary"
                                            label="Description"
                                            fullWidth
                                            multiline
                                            minRows={4}
                                            value={item.description}
                                            onChange={(e) => {onReviewDescriptionUpdate(item.id, e.target.value);}}
                                        />
                                        <Typography variant="h6">Date Created:&nbsp; {new Date(item.dateCreated).toLocaleDateString()}</Typography>
                                        <Typography variant="h6">Date Required:&nbsp; {new Date(item.dateRequired).toLocaleDateString()}</Typography>

                                        <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" style={{marginTop: '10px'}}>
                                            <Button
                                                color="primary"
                                                onClick={() => {onRequestSave(item);}}
                                                disabled={updateTaskResult.loading}
                                            >
                                                {updateTaskResult.loading && <CustomCircularProgress />}
                                                Save
                                            </Button>
                                        </Grid>
                                    </Paper>
                                    <Paper sx={{px:2, py:2, '& .MuiTextField-root': {m:1}}} style={{marginTop: '10px'}}>
                                        <Typography variant="h6">Request Files</Typography>
                                        {(item.attachments && item.attachments.length > 0) ?
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>File Name</TableCell>
                                                        <TableCell>Uploaded By</TableCell>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {item.attachments
                                                    .filter(attachItem => attachItem.taskId === null)
                                                    .map((attachItem, index) => {
                                                        return(
                                                            <TableRow key={index}>
                                                                <TableCell>{attachItem.fileName}</TableCell>
                                                                <TableCell>
                                                                    {(attachItem.uploadedByUser && attachItem.uploadedByUser.firstName && attachItem.uploadedByUser.lastName) ?
                                                                    `${attachItem.uploadedByUser.firstName} ${attachItem.uploadedByUser.lastName}`
                                                                    :
                                                                        ""
                                                                    }

                                                                </TableCell>
                                                                <TableCell>
                                                                    {attachItem.dateUploaded ?
                                                                        new Date(attachItem.dateUploaded).toLocaleDateString()
                                                                    :
                                                                        ""
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                            variant='contained'
                                                                            color='primary'
                                                                            onClick={() => { navigate(`/edit/${requestId}/0/${attachItem.id}/${attachItem.fileName}`)}}
                                                                            disabled={isReviewerFileDownloading(item.id)}
                                                                            style={{display: attachItem.fileName.toLowerCase().endsWith(".pdf") && !item.approved && new Date(item.dateRequired) >= new Date(new Date().setHours(0,0,0,0)) ? "relative": "none", marginRight: "10px"}}
                                                                            >
                                                                            {isReviewerFileDownloading(item.id) && <CustomCircularProgress />}
                                                                            View
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => {downloadFile(item.id, attachItem.id, attachItem.fileName, false)}}
                                                                        disabled={attachItem.loading}
                                                                    >
                                                                        {attachItem.loading && <CustomCircularProgress />}
                                                                        Download
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => {downloadFile(item.id, attachItem.id, attachItem.fileName, true)}}
                                                                        disabled={attachItem.loading}
                                                                        style={{marginLeft: '10px', display: attachItem.annotatedObjectID !== null ? 'relative' : 'none'}}
                                                                    >
                                                                        {attachItem.loading && <CustomCircularProgress />}
                                                                        Download with Annotations
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                                </TableBody>
                                            </Table>
                                        :
                                            <Typography variant="subtitle1">No attachments found</Typography>
                                        }

                                    </Paper>
                                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" style={{marginTop: '10px'}}>
                                        <Typography variant="h5" style={{flex: 1}}>Reviewers</Typography>
                                        {index === 0 &&
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    setRequestAddReviewer(item.id);
                                                    setAddUserDialogOpen(true);
                                                }}
                                            >Add Reviewer</Button>
                                        }

                                    </Grid>
                                    {(item.tasks && item.tasks.length > 0) ?
                                        item.tasks.map((taskItem, index) => {
                                            return(
                                                <Paper key={index} sx={{px:2, py:2, '& .MuiTextField-root': {m:1}}} style={{marginTop: '10px'}}>
                                                    <Grid justifyContent="space-between" container>
                                                        <Grid item>
                                                            <Grid container>
                                                                <Grid item>
                                                                    <Typography variant="h6">Reviewer:&nbsp;</Typography>
                                                                </Grid>
                                                                <Grid item style={{paddingTop: '3px'}}>
                                                                    <Typography variant="subtitle1">{taskItem.assignedTo.email}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item>
                                                                    <Typography variant="h6">Status:&nbsp;</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    {taskItem.approved === null ?
                                                                        new Date(item.dateRequired).getDate() >= new Date().getDate() ?
                                                                            <Typography variant="subtitle1" style={{paddingTop: '3px'}}>Not Yet Reviewed</Typography> 
                                                                        :

                                                                            <Typography variant="subtitle1" style={{paddingTop: '3px'}}>Approved- No Response</Typography>
                                                                    :
                                                                        taskItem.approved === false ?
                                                                            <Typography variant="subtitle1">Rejected <CloseIcon style={{paddingTop: '5px'}}/></Typography>
                                                                        :
                                                                            <Typography variant="subtitle1">Approved <CheckIcon style={{paddingTop: '5px'}}/></Typography>
                                                                    }

                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item>
                                                                    <Typography variant="h6">Comments:&nbsp;</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <div>
                                                                        { taskItem.comments }
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid>
                                                                <Grid item>
                                                                    <Typography variant="h6">Files:&nbsp;</Typography>
                                                                </Grid>
                                                                <Grid>
                                                                {item.attachments.map((attachmentItem, index) => {
                                                                    return(
                                                                        attachmentItem.taskId === taskItem.id &&
                                                                        <TableRow key={index}>
                                                                            <TableCell>{attachmentItem.fileName}</TableCell>
                                                                            <TableCell>
                                                                                <Button
                                                                                        variant='contained'
                                                                                        color='primary'
                                                                                        onClick={() => { navigate(`/edit/${requestId}/0/${attachmentItem.id}/${attachmentItem.fileName}`)}}
                                                                                        disabled={isReviewerFileDownloading(item.id)}
                                                                                        style={{display: attachmentItem.fileName.toLowerCase().endsWith(".pdf") && !item.approved && new Date(item.dateRequired) >= new Date(new Date().setHours(0,0,0,0)) ? "relative": "none", marginRight: "10px"}}
                                                                                        >
                                                                                        {isReviewerFileDownloading(item.id) && <CustomCircularProgress />}
                                                                                        Annotate
                                                                                </Button>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={() => {downloadFile(item.id, attachmentItem.id, attachmentItem.fileName, false)}}
                                                                                    disabled={attachmentItem.loading}
                                                                                >
                                                                                    {attachmentItem.loading && <CustomCircularProgress />}
                                                                                    Download
                                                                                </Button>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={() => {downloadFile(item.id, attachmentItem.id, attachmentItem.fileName, true)}}
                                                                                    disabled={attachmentItem.loading}
                                                                                    style={{marginLeft: '10px', display: attachmentItem.annotatedObjectID !== null ? 'relative' : 'none'}}
                                                                                >
                                                                                    {attachmentItem.loading && <CustomCircularProgress />}
                                                                                    Download with Annotations
                                                                                </Button>
                                                                            </TableCell>
                                                                    </TableRow>
                                                                    );
                                                                })
                                                            }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            {taskItem.approved === null &&
                                                                <Button
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    onClick={() => {onRemoveUserClick(item.id, taskItem.id, taskItem.assignedTo.email)}}
                                                                    disabled={tasks.filter(t => t.id === taskItem.id).removalLoading}
                                                                >
                                                                    Remove Reviewer
                                                                </Button>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            );
                                        })
                                    :
                                        <Typography variant="subtitle1">No Reviewers Found</Typography>
                                    }
                                    </div>
                                </AccordionDetails>
                                </Accordion>
                            </ React.Fragment>
                        );
                    })}

                    {/* <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                    /> */}

                    <Dialog open={deleteRequestDialogOpen} fullWidth maxWidth='md' onClose={cancelDeleteDialog}>
                        <DialogTitle sx={{m:0,p:2}}>
                            <Typography variant="h6" component="span">Delete Request?</Typography>
                            {cancelDeleteDialog ? (
                                <IconButton aria-label="close" sx={{position: 'absolute',right: 8,top: 8,color: grey[500],}} onClick={cancelDeleteDialog} size="large">
                                <CloseIcon />
                                </IconButton>
                            ) : null}
                        </DialogTitle>

                        <Container sx={{px:2, py:2, '& .MuiTextField-root': {m:1}}}>
                            {/* I am here, build details for delete here */}
                            <Typography variant="body1">Are you sure you want to delete the request?</Typography>
                        </Container>

                        <DialogActions sx={{m:0,p:1}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {handleDeleteRequest(requestForDelete)}}
                                disabled={deleteRequestResult.loading}
                            >
                                {deleteRequestResult.loading && <CustomCircularProgress />}
                                Delete Request
                            </Button>
                            <Button variant="contained" color="secondary" onClick={cancelDeleteDialog}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={addUserDialogOpen} fullWidth maxWidth='md' onClose={handleDialogClose}>
                        <DialogTitle sx={{m:0,p:2}}>
                            <Typography variant="h6">Add Reviewer</Typography>
                            {handleDialogClose ? (
                                <IconButton aria-label="close" sx={{position: 'absolute',right: 8,top: 8,color: grey[500],}} onClick={handleDialogClose} size="large">
                                <CloseIcon />
                                </IconButton>
                            ) : null}
                        </DialogTitle>
                        <Container sx={{px:2, py:2, '& .MuiTextField-root': {m:1}}}>
                        <PeoplePicker
                            _helperText=""
                            _peopleValue={newReviewer}
                            _setPeopleValue={setNewReviewer}
                            _label="User"
                            _isMultiple={false}
                            _required={false}
                            _internalUsers={false}
                            style={{padding: '10px'}}
                        />
                        </Container>

                        <DialogActions sx={{m:0,p:1}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {onAddReviewerToRequest(requestAddReviewer, newReviewer.guid);}}
                                disabled={addUserToRequestResult.loading}
                            >
                                {addUserToRequestResult.loading && <CustomCircularProgress />}
                                Add Reviewer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {(requestQueryResult.loading) && (
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 9999,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress color="primary" />
                        </div>
                    )}
                </>
            :
                <CircularProgress color="primary"/>
            }
        </Container>
    );
}